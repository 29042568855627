<template>
  <div>
    <div class="pl-lg-4">
      <template v-if="action === 'create'">
        <div class="row">
          <div class="pt10 col-md-2 col-lg-1">{{ value['year'] }}年</div>
          <div class="col-md-3 col-lg-2" v-if="currentTabIndex !== 2">
            <b-form-select v-model="selectedMonth" :options="monthOptions"
                           @input="changeMonth(selectedMonth)"/>
          </div>
          <div class="pt10 col-md-2 col-lg-1" v-else>{{ value['month'] }}月</div>
          <div class="pt10 col-md-3 col-lg-2">
            基準出勤日数：{{ value['basic_attendance_day'] }}
          </div>
          <div class="pt10 checkbox-default" v-if="currentTabIndex !== 2">
            <b-form-checkbox v-model="isDefaultInputTab" @input="checkDefaultInput()">
              <span>デフォルト勤怠入力</span>
            </b-form-checkbox>
          </div>
        </div>
      </template>
      <template v-else>
        <b-row>
          <b-col md="2" lg="1">{{ value['year'] }}年</b-col>
          <b-col md="2" lg="1">{{ value['month'] }}月</b-col>
          <b-col md="3" lg="2">
            基準出勤日数：{{ value['basic_attendance_day'] }}
          </b-col>
          <b-col class="checkbox-default" v-if="currentTabIndex !== 2">
            <b-form-checkbox v-model="isDefaultInputTab" @input="checkDefaultInput()">
              <span>デフォルト勤怠入力</span>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </template>
    </div>
    <hr class="my-4">
    <b-tabs v-model="currentTabIndex">
      <b-tab title="現場勤怠">
        <div style="margin-top:1.5rem;">
          <b-form @submit.prevent class="attendance-form">
            <validation-observer ref="observer">
              <div class="pl-lg-4 setting-container mt20">
                <b-table-simple>
                  <b-tbody>
                    <b-tr>
                      <b-th class="col-sm-2 text-right">基本就業時間</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`work_start_time_2`)'
                              :error='get_error_by_name(`work_start_time_2`)'
                              v-model="value[`work_start_time_2`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`work_end_time_2`)'
                              :error='get_error_by_name(`work_end_time_2`)'
                              v-model="value[`work_end_time_2`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                      <b-th class="col-sm-2 text-right">休憩時間1</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_start_time_1_2`)"
                              :error="get_error_by_name(`break_start_time_1_2`)"
                              v-model="value[`break_start_time_1_2`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_end_time_1_2`)"
                              :error="get_error_by_name(`break_end_time_1_2`)"
                              v-model="value[`break_end_time_1_2`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-th class="col-sm-2 text-right">早出時間</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`early_start_time_2`)'
                              :error='get_error_by_name(`early_start_time_2`)'
                              v-model="value[`early_start_time_2`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`early_end_time_2`)'
                              :error='get_error_by_name(`early_end_time_2`)'
                              v-model="value[`early_end_time_2`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                      <b-th class="col-sm-2 text-right">休憩時間2</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_start_time_2_2`)"
                              :error="get_error_by_name(`break_start_time_2_2`)"
                              v-model="value[`break_start_time_2_2`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_end_time_2_2`)"
                              :error="get_error_by_name(`break_end_time_2_2`)"
                              v-model="value[`break_end_time_2_2`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="col-sm-2 text-right">残業時間</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`start_overtime_2`)'
                              :error='get_error_by_name(`start_overtime_2`)'
                              v-model="value[`start_overtime_2`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`end_overtime_2`)'
                              :error='get_error_by_name(`end_overtime_2`)'
                              v-model="value[`end_overtime_2`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                      <b-th class="col-sm-2 text-right">休憩時間3</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_start_time_3_2`)"
                              :error="get_error_by_name(`break_start_time_3_2`)"
                              v-model="value[`break_start_time_3_2`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_end_time_3_2`)"
                              :error="get_error_by_name(`break_end_time_3_2`)"
                              v-model="value[`break_end_time_3_2`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="col-sm-2 text-right">早朝・深夜就業時間</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`late_start_time_2`)'
                              :error='get_error_by_name(`late_start_time_2`)'
                              v-model="value[`late_start_time_2`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`late_end_time_2`)'
                              :error='get_error_by_name(`late_end_time_2`)'
                              v-model="value[`late_end_time_2`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                      <b-th class="col-sm-2 text-right">休憩時間4</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_start_time_4_2`)"
                              :error="get_error_by_name(`break_start_time_4_2`)"
                              v-model="value[`break_start_time_4_2`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_end_time_4_2`)"
                              :error="get_error_by_name(`break_end_time_4_2`)"
                              v-model="value[`break_end_time_4_2`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="col-sm-2 text-right">お昼休み時間</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`lunch_start_time_2`)'
                              :error='get_error_by_name(`lunch_start_time_2`)'
                              v-model="value[`lunch_start_time_2`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`lunch_end_time_2`)'
                              :error='get_error_by_name(`lunch_end_time_2`)'
                              v-model="value[`lunch_end_time_2`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                      <b-th class="col-sm-2 text-right">休憩時間5</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_start_time_5_2`)"
                              :error="get_error_by_name(`break_start_time_5_2`)"
                              v-model="value[`break_start_time_5_2`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_end_time_5_2`)"
                              :error="get_error_by_name(`break_end_time_5_2`)"
                              v-model="value[`break_end_time_5_2`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              <div class="pl-lg-4 duty-container mt20">
                <table class="table b-table table-sm b-table-stacked-md">
                  <thead>
                  <tr>
                    <th>日</th>
                    <th>曜日</th>
                    <th>区分</th>
                    <th>出社時刻</th>
                    <th>退社時刻</th>
                    <th>開始時刻</th>
                    <th>終了時刻</th>
                    <th>基本就業時間</th>
                    <th>早出<br>残業</th>
                    <th>早朝<br>深夜</th>
                    <th>合計</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(day, index) in calendarDays" :key="index"
                      :style="{ 'background-color': applyBackgroundColor(day, 2) }">
                    <td>{{ day }}</td>
                    <td>{{ weekday(day) }}</td>
                    <td>
                      <!-- 标记 -->
                      <AttendanceControlCreator :field='get_field_by_name_1(`type_${day}_2`)'
                                                :error='get_error_by_name(`type_${day}_2`)'
                                                v-model="value[`type_${day}_2`]" :data="value"
                                                @input="changeType(day, 2)"/>
                    </td>
                    <td>
                      <AttendanceControlCreator :field='get_field_by_name(`start_time_${day}_2`)'
                                                :error='get_error_by_name(`start_time_${day}_2`)'
                                                v-model="value[`start_time_${day}_2`]"
                                                :data="value"
                                                @input="updateValue"/>
                    </td>
                    <td>
                      <AttendanceControlCreator :field='get_field_by_name(`end_time_${day}_2`)'
                                                :error='get_error_by_name(`end_time_${day}_2`)'
                                                v-model="value[`end_time_${day}_2`]" :data="value"
                                                @input="updateValue"/>
                    </td>
                    <td>{{ value[`base_start_time_${day}_2`] }}</td>
                    <td>{{ value[`base_end_time_${day}_2`] }}</td>
                    <td>{{ value[`base_time_${day}_2`] }}</td>
                    <td>{{ value[`over_${day}_2`] }}</td>
                    <td>{{ value[`night_over_${day}_2`] }}</td>
                    <td>{{ value[`total_time_${day}_2`] }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="pl-lg-4 mt20 totalling-container">
                <b-table-simple>
                  <b-tbody>
                    <b-tr>
                      <b-th class="text-right">出勤日数</b-th>
                      <b-td>{{ value['attendance_days_2'] }}</b-td>
                      <b-th class="text-right">出勤日数算出</b-th>
                      <b-td>{{ value['attendance_time_2'] }}</b-td>
                      <b-th class="text-right">総就業時間</b-th>
                      <b-td>{{ value['total_work_time_2'] }}</b-td>
                      <b-th class="text-right">普通残業時間</b-th>
                      <b-td>{{ value['total_early_time_2'] }}</b-td>
                      <b-th class="text-right">普通残業時間算出</b-th>
                      <b-td>{{ value['over_time_2'] }}</b-td>
                      <b-th class="text-right">深夜残業時間</b-th>
                      <b-td>{{ value['total_night_time_2'] }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right">法定休日<br>出勤日数</b-th>
                      <b-td>{{ value['legal_holiday_2'] }}</b-td>
                      <b-th class="text-right">法定休日<br>出勤時間</b-th>
                      <b-td>{{ value['legal_holiday_time_2'] }}</b-td>
                      <b-th class="text-right">法定休日<br>残業時間</b-th>
                      <b-td>{{ value['legal_hol_over_2'] }}</b-td>
                      <b-th class="text-right">法定休日<br>深夜残業時間</b-th>
                      <b-td>{{ value['legal_hol_night_2'] }}</b-td>
                      <b-th class="text-right">代休取得日数</b-th>
                      <b-td>{{ value['compensatory_days_2'] }}</b-td>
                      <b-th class="text-right">振替取得日数</b-th>
                      <b-td>{{ value['compensatory_leave_2'] }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right">法定外休日<br>出勤日数</b-th>
                      <b-td>{{ value['nonlegal_hol_2'] }}</b-td>
                      <b-th class="text-right">法定外休日<br>出勤時間</b-th>
                      <b-td>{{ value['nonlegal_hol_time_2'] }}</b-td>
                      <b-th class="text-right">法定外休日<br>残業時間</b-th>
                      <b-td>{{ value['nonlegal_hol_over_2'] }}</b-td>
                      <b-th class="text-right">法定外休日<br>深夜残業時間</b-th>
                      <b-td>{{ value['nonlegal_hol_night_2'] }}</b-td>
                      <b-th class="text-right">有休取得日数</b-th>
                      <b-td>{{ value['paid_vacation_days_2'] }}</b-td>
                      <b-th class="text-right">有休時間</b-th>
                      <b-td>{{ value['paid_vacation_time_2'] }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right">欠勤日数</b-th>
                      <b-td>{{ value['absence_days_2'] }}</b-td>
                      <b-th class="text-right">欠勤日数算出</b-th>
                      <b-td>{{ value['absence_calculate_2'] }}</b-td>
                      <b-th class="text-right">欠勤時間</b-th>
                      <b-td>{{ value['absence_time_2'] }}</b-td>
                      <b-th class="text-right">超過残業時間(60時間)</b-th>
                      <b-td>{{ value['excess_over_2'] }}</b-td>
                      <b-th class="text-right">総合計時間</b-th>
                      <b-td>{{ value['amount_time_2'] }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              <div class="pl-lg-4 mt20 file-upload">
                <AttendanceControlCreator :field='get_field_by_name(`work_time_file`)'
                                          :error='get_error_by_name(`work_time_file`)'
                                          v-model="value[`work_time_file`]"
                                          :data="value" @input="updateValue"/>
              </div>
              <div class="pl-lg-4 mt20 file-upload" v-if="value['work_time_file']">
                <AttendanceControlCreator :field='get_field_by_name(`file_del`)'
                                          :error='get_error_by_name(`file_del`)'
                                          v-model="value[`file_del`]" :data="value"
                                          @input="updateValue"/>
              </div>
              <div class="pl-lg-4 mt20">
                <AttendanceControlCreator :field='get_field_by_name(`remark_2`)'
                                          :error='get_error_by_name(`remark_2`)'
                                          v-model="value[`remark_2`]" :data="value"
                                          @input="updateValue"/>
              </div>
            </validation-observer>
          </b-form>
        </div>
      </b-tab>
      <b-tab title="社内勤怠">
        <div style="margin-top:1.5rem;">
          <b-form @submit.prevent class="attendance-form">
            <validation-observer ref="observer">
              <div class="pl-lg-4 setting-container mt20">
                <b-table-simple>
                  <b-tbody>
                    <b-tr>
                      <b-th class="col-sm-2 text-right">基本就業時間</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`work_start_time`)'
                              :error='get_error_by_name(`work_start_time`)'
                              v-model="value[`work_start_time`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`work_end_time`)'
                              :error='get_error_by_name(`work_end_time`)'
                              v-model="value[`work_end_time`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                      <b-th class="col-sm-2 text-right">休憩時間1</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_start_time_1`)"
                              :error="get_error_by_name(`break_start_time_1`)"
                              v-model="value[`break_start_time_1`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_end_time_1`)"
                              :error="get_error_by_name(`break_end_time_1`)"
                              v-model="value[`break_end_time_1`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-th class="col-sm-2 text-right">早出時間</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`early_start_overtime`)'
                              :error='get_error_by_name(`early_start_overtime`)'
                              v-model="value[`early_start_overtime`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`early_end_overtime`)'
                              :error='get_error_by_name(`early_end_overtime`)'
                              v-model="value[`early_end_overtime`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                      <b-th class="col-sm-2 text-right">休憩時間2</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_start_time_2`)"
                              :error="get_error_by_name(`break_start_time_2`)"
                              v-model="value[`break_start_time_2`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_end_time_2`)"
                              :error="get_error_by_name(`break_end_time_2`)"
                              v-model="value[`break_end_time_2`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="col-sm-2 text-right">残業時間</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`start_overtime`)'
                              :error='get_error_by_name(`start_overtime`)'
                              v-model="value[`start_overtime`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`end_overtime`)'
                              :error='get_error_by_name(`end_overtime`)'
                              v-model="value[`end_overtime`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                      <b-th class="col-sm-2 text-right">休憩時間3</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_start_time_3`)"
                              :error="get_error_by_name(`break_start_time_3`)"
                              v-model="value[`break_start_time_3`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_end_time_3`)"
                              :error="get_error_by_name(`break_end_time_3`)"
                              v-model="value[`break_end_time_3`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="col-sm-2 text-right">早朝・深夜就業時間</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`late_start_time`)'
                              :error='get_error_by_name(`late_start_time`)'
                              v-model="value[`late_start_time`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`late_end_time`)'
                              :error='get_error_by_name(`late_end_time`)'
                              v-model="value[`late_end_time`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                      <b-th class="col-sm-2 text-right">休憩時間4</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_start_time_4`)"
                              :error="get_error_by_name(`break_start_time_4`)"
                              v-model="value[`break_start_time_4`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_end_time_4`)"
                              :error="get_error_by_name(`break_end_time_4`)"
                              v-model="value[`break_end_time_4`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="col-sm-2 text-right">お昼休み時間</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`lunch_start_time`)'
                              :error='get_error_by_name(`lunch_start_time`)'
                              v-model="value[`lunch_start_time`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field='get_field_by_name(`lunch_end_time`)'
                              :error='get_error_by_name(`lunch_end_time`)'
                              v-model="value[`lunch_end_time`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                      <b-th class="col-sm-2 text-right">休憩時間5</b-th>
                      <b-td class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_start_time_5`)"
                              :error="get_error_by_name(`break_start_time_5`)"
                              v-model="value[`break_start_time_5`]" :data="value"
                              @blur="roundUpTime" @input="updateValue"/>
                          </div>
                          <div class="pt10">～</div>
                          <div class="col-sm-5">
                            <AttendanceControlCreator
                              :field="get_field_by_name(`break_end_time_5`)"
                              :error="get_error_by_name(`break_end_time_5`)"
                              v-model="value[`break_end_time_5`]" :data="value"
                              @blur="roundDownTime" @input="updateValue"/>
                          </div>
                        </div>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              <div class="pl-lg-4 duty-container mt20">
                <table class="table b-table table-sm b-table-stacked-md">
                  <thead>
                  <tr>
                    <th>日</th>
                    <th>曜日</th>
                    <th>区分</th>
                    <th>出社時刻</th>
                    <th>退社時刻</th>
                    <th>開始時刻</th>
                    <th>終了時刻</th>
                    <th>基本就業時間</th>
                    <th>早出<br>残業</th>
                    <th>早朝<br>深夜</th>
                    <th>合計</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(day, index) in calendarDays" :key="index"
                      :style="{ 'background-color': applyBackgroundColor(day, 1) }">
                    <td>{{ day }}</td>
                    <td>{{ weekday(day) }}</td>
                    <td>
                      <AttendanceControlCreator :field='get_field_by_name(`type_${day}`)'
                                                :error='get_error_by_name(`type_${day}`)'
                                                v-model="value[`type_${day}`]"
                                                :data="value" @input="changeType(day, 1)"/>
                    </td>
                    <td>
                      <AttendanceControlCreator :field='get_field_by_name(`start_time_${day}`)'
                                                :error='get_error_by_name(`start_time_${day}`)'
                                                v-model="value[`start_time_${day}`]" :data="value"
                                                @input="updateValue"/>
                    </td>
                    <td>
                      <AttendanceControlCreator :field='get_field_by_name(`end_time_${day}`)'
                                                :error='get_error_by_name(`end_time_${day}`)'
                                                v-model="value[`end_time_${day}`]" :data="value"
                                                @input="updateValue"/>
                    </td>
                    <td>{{ value[`basic_start_time_${day}`] }}</td>
                    <td>{{ value[`basic_end_time_${day}`] }}</td>
                    <td>{{ value[`basic_time_${day}`] }}</td>
                    <td>{{ value[`over_${day}`] }}</td>
                    <td>{{ value[`night_over_${day}`] }}</td>
                    <td>{{ value[`total_time_${day}`] }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="pl-lg-4 mt20 totalling-container">
                <b-table-simple>
                  <b-tbody>
                    <b-tr>
                      <b-th class="text-right">出勤日数</b-th>
                      <b-td>{{ value['attendance_days'] }}</b-td>
                      <b-th class="text-right">出勤日数算出</b-th>
                      <b-td>{{ value['attendance_time'] }}</b-td>
                      <b-th class="text-right">総就業時間</b-th>
                      <b-td>{{ value['total_work_time'] }}</b-td>
                      <b-th class="text-right">普通残業時間</b-th>
                      <b-td>{{ value['total_early_overtime'] }}</b-td>
                      <b-th class="text-right">普通残業時間算出</b-th>
                      <b-td>{{ value['over_time'] }}</b-td>
                      <b-th class="text-right">深夜残業時間</b-th>
                      <b-td>{{ value['total_night_overtime'] }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-th class="text-right">法定休日<br>出勤日数</b-th>
                      <b-td>{{ value['legal_holiday'] }}</b-td>
                      <b-th class="text-right">法定休日<br>出勤時間</b-th>
                      <b-td>{{ value['legal_holiday_time'] }}</b-td>
                      <b-th class="text-right">法定休日<br>残業時間</b-th>
                      <b-td>{{ value['legal_hol_over'] }}</b-td>
                      <b-th class="text-right">法定休日<br>深夜残業時間</b-th>
                      <b-td>{{ value['legal_hol_night'] }}</b-td>
                      <b-th class="text-right">代休取得日数</b-th>
                      <b-td>{{ value['compensatory_days'] }}</b-td>
                      <b-th class="text-right">振替取得日数</b-th>
                      <b-td>{{ value['compensatory_leave'] }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right">法定外休日<br>出勤日数</b-th>
                      <b-td>{{ value['nonlegal_hol'] }}</b-td>
                      <b-th class="text-right">法定外休日<br>出勤時間</b-th>
                      <b-td>{{ value['nonlegal_hol_time'] }}</b-td>
                      <b-th class="text-right">法定外休日<br>残業時間</b-th>
                      <b-td>{{ value['nonlegal_hol_over'] }}</b-td>
                      <b-th class="text-right">法定外休日<br>深夜残業時間</b-th>
                      <b-td>{{ value['nonlegal_hol_night'] }}</b-td>
                      <b-th class="text-right">有休取得日数</b-th>
                      <b-td>{{ value['paid_vacation_days'] }}</b-td>
                      <b-th class="text-right">有休時間</b-th>
                      <b-td>{{ value['paid_vacation_time'] }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right">欠勤日数</b-th>
                      <b-td>{{ value['absence_days'] }}</b-td>
                      <b-th class="text-right">欠勤日数算出</b-th>
                      <b-td>{{ value['absence_calculate'] }}</b-td>
                      <b-th class="text-right">欠勤時間</b-th>
                      <b-td>{{ value['absence_time'] }}</b-td>
                      <b-th class="text-right">超過残業時間(60時間)</b-th>
                      <b-td>{{ value['excess_over'] }}</b-td>
                      <b-th class="text-right">総合計時間</b-th>
                      <b-td>{{ value['amount_time'] }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              <div class="pl-lg-4 mt20">
                <AttendanceControlCreator :field='get_field_by_name(`remark`)'
                                          :error='get_error_by_name(`remark`)' v-model="value[`remark`]"
                                          :data="value"
                                          @input="updateValue"/>
              </div>
            </validation-observer>
          </b-form>
        </div>
      </b-tab>
      <b-tab title="合併勤怠">
        <div style="margin-top:1.5rem;">
          <b-form @submit.prevent class="attendance-form">
            <validation-observer ref="observer">
              <div class="pl-lg-4 duty-container mt20">
                <table class="table b-table table-sm b-table-stacked-md">
                  <thead>
                  <tr>
                    <th>日</th>
                    <th>曜日</th>
                    <th>現場区分</th>
                    <th>社内区分</th>
                    <th>出社時刻</th>
                    <th>退社時刻</th>
                    <th>基本就業時間</th>
                    <th>中抜時間</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(day, index) in calendarDays" :key="index"
                      :style="{ 'background-color': applyWeekendColor(day) }">
                    <td>{{ day }}</td>
                    <td>{{ weekday(day) }}</td>
                    <td>{{ showTypeText(day, 2) }}</td>
                    <td>{{ showTypeText(day, 1) }}</td>
                    <td>{{ value[`start_time_${day}_3`] }}</td>
                    <td>{{ value[`end_time_${day}_3`] }}</td>
                    <td>{{ value[`basic_time_${day}_3`] }}</td>
                    <td>{{ value[`step_out_time_${day}`] }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="pl-lg-4 mt20 totalling-container">
                <b-table-simple>
                  <b-tbody>
                    <b-tr>
                      <b-th class="text-right">出勤日数</b-th>
                      <b-td>{{ value['attendance_days_3'] }}</b-td>
                      <b-th class="text-right">出勤日数算出</b-th>
                      <b-td>{{ value['attendance_time_3'] }}</b-td>
                      <b-th class="text-right">総就業時間</b-th>
                      <b-td>{{ value['total_work_time_3'] }}</b-td>
                      <b-th class="text-right">普通残業時間</b-th>
                      <b-td>{{ value['total_early_time_3'] }}</b-td>
                      <b-th class="text-right">普通残業時間算出</b-th>
                      <b-td>{{ value['over_time_3'] }}</b-td>
                      <b-th class="text-right">深夜残業時間</b-th>
                      <b-td>{{ value['total_night_time_3'] }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right">法定休日<br>出勤日数</b-th>
                      <b-td>{{ value['legal_holiday_3'] }}</b-td>
                      <b-th class="text-right">法定休日<br>出勤時間</b-th>
                      <b-td>{{ value['legal_holiday_time_3'] }}</b-td>
                      <b-th class="text-right">法定休日<br>残業時間</b-th>
                      <b-td>{{ value['legal_hol_over_3'] }}</b-td>
                      <b-th class="text-right">法定休日<br>深夜残業時間</b-th>
                      <b-td>{{ value['legal_hol_night_3'] }}</b-td>
                      <b-th class="text-right">代休取得日数</b-th>
                      <b-td>{{ value['compensatory_days_3'] }}</b-td>
                      <b-th class="text-right">振替取得日数</b-th>
                      <b-td>{{ value['compensatory_leave_3'] }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right">法定外休日<br>出勤日数</b-th>
                      <b-td>{{ value['nonlegal_hol_3'] }}</b-td>
                      <b-th class="text-right">法定外休日<br>出勤時間</b-th>
                      <b-td>{{ value['nonlegal_hol_time_3'] }}</b-td>
                      <b-th class="text-right">法定外休日<br>残業時間</b-th>
                      <b-td>{{ value['nonlegal_hol_over_3'] }}</b-td>
                      <b-th class="text-right">法定外休日<br>深夜残業時間</b-th>
                      <b-td>{{ value['nonlegal_hol_night_3'] }}</b-td>
                      <b-th class="text-right">有休取得日数</b-th>
                      <b-td>{{ value['paid_vacation_days_3'] }}</b-td>
                      <b-th class="text-right">有休時間</b-th>
                      <b-td>{{ value['paid_vacation_time_3'] }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="text-right">欠勤日数</b-th>
                      <b-td>{{ value['absence_days_3'] }}</b-td>
                      <b-th class="text-right">欠勤日数算出</b-th>
                      <b-td>{{ value['absence_calculate_3'] }}</b-td>
                      <b-th class="text-right">欠勤時間</b-th>
                      <b-td>{{ value['absence_time_3'] }}</b-td>
                      <b-th class="text-right">超過残業時間(60時間)</b-th>
                      <b-td>{{ value['excess_over_3'] }}</b-td>
                      <b-th class="text-right">総合計時間</b-th>
                      <b-td>{{ value['amount_time_3'] }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </validation-observer>
          </b-form>
        </div>
      </b-tab>
    </b-tabs>
    <hr class="my-4">
    <b-card no-body>
      <b-card-header class="border-0">
        <h3 class="mb-0 d-inline">休暇履歴</h3>
      </b-card-header>
      <b-table
        :items="vacationDays"
        :fields="vacationFields"
        stacked="md"
        show-empty
        small
        hover
      >
        <template #cell(is_start_half)="row">
          <b-icon v-if="row.value" icon="check-circle-fill" variant="success"></b-icon>
          <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {ajax, common, config} from '../../utils';
import AttendanceControlCreator from './AttendanceControlCreator';
import {helper} from '../../views/common';
import {mapState} from "vuex";

export default {
  name: 'attendance-form',
  components: {
    AttendanceControlCreator,
  },
  props: {
    schema: {type: Array, default: () => [], required: true},
    layout: {type: Array, default: null},
    value: {type: Object, default: () => ({})},
    error: {type: Object, default: () => ({})},
    action: {type: String, default: ''},
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      selectedMonth: null,
      monthOptions: [],
      vacationsDisabledDays: [],
      currentTabIndex: 0,
      isDefaultInputTab1: false,
      isDefaultInputTab2: false,
      businessDays: [],
      vacationDays: [],
      programmaticChange: false,
    };
  },
  methods: {
    get_field_by_name_1(name) {
      const _schema = this.schema.find(i => i.name === name);
      const filteredChoices = _schema.choices && _schema.choices.filter(item => item.value !== '03');
      const updatedSchema = {
        ..._schema,
        choices: filteredChoices
      };
      return updatedSchema
    },

    get_field_by_name(name) {
      return this.schema.find(i => i.name === name)
    },
    get_error_by_name(name) {
      if (this.error && name in this.error) {
        const errors = this.error[name];
        if (Array.isArray(errors) && errors.length > 0) {
          return errors[0]
        } else {
          return errors;
        }
      } else {
        return null;
      }
    },
    updateValue(value, name) {
      const data = common.clone(this.value);
      Object.assign(data, {[name]: value});
      this.$emit("input", data);
    },
    validate() {
      return this.$refs.observer.validate()
    },
    roundUpTime(event, name) {
      if (event.target.value) {
        //（社内勤怠）基本就業開始時間と早出終了時間を一致させる。
        if (name === 'work_start_time') {
          this.value[`early_end_overtime`] = this.value[name];
        }
        //（現場勤怠）基本就業開始時間と早出終了時間を一致させる。
        if (name === 'work_start_time_2') {
          this.value[`early_end_time_2`] = this.value[name];
        }
        // 有休計算
        if (['work_start_time', 'work_start_time_2'].includes(name)) {
          this.calculateContractDate();
        }
      }
    },
    roundDownTime(event, name) {
      if (event.target.value) {
        //（社内勤怠）基本就業終了時間と残業開始時間を一致させる。
        if (name === 'work_end_time') {
          this.value[`start_overtime`] = this.value[name];
        }
        //（現場勤怠）基本就業終了時間と残業開始時間を一致させる。
        if (name === 'work_end_time_2') {
          this.value[`start_overtime_2`] = this.value[name];
        }
        // 有休計算
        if (['work_end_time', 'work_end_time_2'].includes(name)) {
          this.calculateContractDate();
        }
      }
    },
    //勤怠区分SelectBoxの切替イベント
    changeType(day, tabType) {
      if (this.programmaticChange) {
        return;
      }
      let startTime = `start_time_${day}`;
      let endTime = `end_time_${day}`;
      let type = `type_${day}`;
      let workStartTime = `work_start_time`;
      let workEndime = `work_end_time`;
      //現場勤怠の場合
      if (tabType === 2) {
        startTime = `start_time_${day}_2`;
        endTime = `end_time_${day}_2`;
        type = `type_${day}_2`;
        workStartTime = `work_start_time_2`;
        workEndime = `work_end_time_2`;
      }
      this.schema.find(i => i.name === startTime).read_only = false;
      this.schema.find(i => i.name === endTime).read_only = false;
      //定休、休日の場合
      if (this.value[type] === '07' || this.value[type] === '10') {
        this.schema.find(i => i.name === startTime).read_only = true;
        this.schema.find(i => i.name === endTime).read_only = true;
        this.value[startTime] = null;
        this.value[endTime] = null;
      }
      //出勤、有給の場合
      if (this.value[type] === '01' || this.value[type] === '03') {
        if (!this.value[startTime] || !this.value[endTime]) {
          this.value[startTime] = this.value[workStartTime];
          this.value[endTime] = this.value[workEndime];
        }
      }
      if (this.value[type] === null) {
        this.value[startTime] = null;
        this.value[endTime] = null;
      }
    },
    applyBackgroundColor(day, tabType) {
      let color_code = '';
      let startTime = `start_time_${day}`;
      let endTime = `end_time_${day}`;
      let type = `type_${day}`;
      //現場勤怠の場合
      if (tabType === 2) {
        startTime = `start_time_${day}_2`;
        endTime = `end_time_${day}_2`;
        type = `type_${day}_2`;
      }
      //有休、代休、休日の場合
      if (this.value[type] === '03' || this.value[type] === '04' || this.value[type] === '10') {
        if (!this.isDiffHours(this.value[startTime], this.value[endTime])) {
          color_code = '#E9E9E9';
        } else {
          color_code = '#F5F5D2';
        }
      }
      //欠勤の場合
      if (this.value[type] === '02' && !this.vacationsDisabledDays.includes(day)) {
        color_code = '#FFDDE1';
      }
      //土曜日、日曜日の場合
      if (this.value[type] === null && (this.weekday(day) === '土' || this.weekday(day) === '日')) {
        color_code = '#E9E9E9';
      }
      return color_code;
    },
    applyWeekendColor(day) {
      let color_code = '';
      //土曜日、日曜日の場合
      if ((this.weekday(day) === '土' || this.weekday(day) === '日')) {
        color_code = '#E9E9E9';
      }
      return color_code;
    },
    isDiffHours(start_time, end_time) {
      const start = new Date(`1970/01/01 ${start_time}`);
      const end = new Date(`1970/01/01 ${end_time}`);
      const diffMilliseconds = end - start;
      const diffHours = diffMilliseconds / (1000 * 60 * 60);
      return diffHours > 0;
    },
    //勤怠区分項目の表示関連
    isTimeDisabled(tabType) {
      for (const day of this.calendarDays) {
        let startTime = `start_time_${day}`;
        let endTime = `end_time_${day}`;
        let type = `type_${day}`;
        //現場勤怠
        if (tabType === 2) {
          startTime = `start_time_${day}_2`;
          endTime = `end_time_${day}_2`;
          type = `type_${day}_2`;
          //現場勤怠の場合、【区分】から「有給」を除く。
          // this.$nextTick(() => {
          //     this.schema.forEach(item => {
          //         if (item.name === type) {
          //             item.choices = item.choices.filter(itm => itm.value != '03');
          //         }
          //     });
          // });
        }

        //勤怠編集画面で、区分が定休と休日の場合、勤怠時刻Inputを非活性に表示。
        if (this.value[type] === '07' || this.value[type] === '10') {
          this.schema.forEach(item => {
            if (item.name === startTime) {
              item.read_only = true;
            }
            if (item.name === endTime) {
              item.read_only = true;
            }
          });
        }
      }
    },
    showTypeText(day, tabType) {
      // console.log(tabType)
      let typeKey = `type_${day}`;
      //現場勤怠の場合
      if (tabType === 2) {
        typeKey = `type_${day}_2`;
      }
      const type = this.value[typeKey];
      switch (type) {
        case '01':
          return '出勤';
        case '02':
          return '欠勤';
        case '03':
          return '有給';
        case '04':
          return '代休';
        case '05':
          return '休日出勤';
        case '06':
          return '在宅ワーク';
        case '07':
          return '定休';
        case '08':
          return '遅刻';
        case '09':
          return '早退';
        case '10':
          return '休日';
        case '11':
          return '振替';
        case '12':
          return '休出・在宅';
        case '13':
          return '休出・遅刻';
        case '14':
          return '休出・早退';
        default:
          return '';
      }
    },
    //新規画面で、月分SelectBoxの切替イベント
    changeMonth(val) {
      const date = val.split('-');
      this.value['year'] = date[0];
      this.value['month'] = date[1];
      this.isDefaultInputTab1 = false;
      this.isDefaultInputTab2 = false;
      const vm = this;
      this.programmaticChange = true;
      for (let day of vm.calendarDays) {
        // 社内勤怠の場合
        let startTime1 = `start_time_${day}`;
        let endTime1 = `end_time_${day}`;
        let type1 = `type_${day}`;

        vm.value[type1] = null;
        vm.value[startTime1] = null;
        vm.value[endTime1] = null;

        // 現場勤怠の場合
        let startTime2 = `start_time_${day}_2`;
        let endTime2 = `end_time_${day}_2`;
        let type2 = `type_${day}_2`;
        vm.value[type2] = null;
        vm.value[startTime2] = null;
        vm.value[endTime2] = null;
      }
      vm.$nextTick(() => {
        vm.programmaticChange = false;
      });
      vm.get_business_days(vm.value['year'], vm.value['month']);
    },
    //月分SelectBoxに今月と前月をロード。
    loadMonthOptions() {
      const currentDate = new Date();
      const currentMonth = this.formatDate(currentDate);

      // 前の月を取得
      const previousMonthDate = new Date(currentDate);
      previousMonthDate.setDate(1);
      previousMonthDate.setMonth(currentDate.getMonth() - 1);
      const previousMonth = this.formatDate(previousMonthDate);

      this.monthOptions = [
        {value: currentMonth, text: currentDate.getMonth() + 1 + '月'},
        {value: previousMonth, text: previousMonthDate.getMonth() + 1 + '月'},
      ];

      // 初期選択を設定する場合
      this.selectedMonth = currentMonth;
      this.value['month'] = currentDate.getMonth() + 1;
    },
    //年度を跨いだ場合の対応
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月を2桁にする
      return `${year}-${month}`;
    },
    //デフォルト勤怠入力
    checkDefaultInput() {
      const vm = this;
      // console.log(vm.currentTabIndex)
      const year = vm.value['year'];
      const month = vm.value['month'];
      if (vm.currentTabIndex === 1) {    //社内勤怠の場合
        // this.isDefaultInputTab
        for (let day of vm.calendarDays) {
          let type = `type_${day}`;
          const fullDay = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
          if (vm.vacationsDisabledDays.includes(day) || vm.get_field_by_name(type).read_only || !vm.businessDays.includes(fullDay)) {
            continue;
          }
          let startTime = `start_time_${day}`;
          let endTime = `end_time_${day}`;
          let workStartTime = `work_start_time`;
          let workEndime = `work_end_time`;
          if (vm.isDefaultInputTab1 && (vm.weekday(day) !== '土' && vm.weekday(day) !== '日')) {
            vm.value[type] = '01';
            vm.value[startTime] = vm.value[workStartTime];
            vm.value[endTime] = vm.value[workEndime];
          } else {
            vm.value[type] = null;
            vm.value[startTime] = null;
            vm.value[endTime] = null;
          }
        }
      } else if (vm.currentTabIndex === 0) {     //現場勤怠の場合
        for (let day of vm.calendarDays) {
          let type = `type_${day}_2`;
          const fullDay = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
          if (vm.vacationsDisabledDays.includes(day) || vm.get_field_by_name(type).read_only || !vm.businessDays.includes(fullDay)) {
            continue;
          }
          let startTime = `start_time_${day}_2`;
          let endTime = `end_time_${day}_2`;
          let workStartTime = `work_start_time_2`;
          let workEndime = `work_end_time_2`;
          if (vm.isDefaultInputTab2 && (vm.weekday(day) !== '土' && vm.weekday(day) !== '日')) {
            if (vm.value[type] == null) {
              vm.value[type] = '01';
              vm.value[startTime] = vm.value[workStartTime];
              vm.value[endTime] = vm.value[workEndime];
            }
          } else {
            vm.value[type] = null;
            vm.value[startTime] = null;
            vm.value[endTime] = null;
          }
        }
      }
    },
    adjust_work_time(time, hoursChange) {
      if (!time) {
        return time;
      }
      let [hours, minutes] = time.split(':');
      hours = (Number(hours) + hoursChange) % 24;
      return `${hours.toString().padStart(2, '0')}:${minutes}`;
    },
    //基準出勤日APIを呼び出す
    get_business_days(year, month) {
      const vm = this;
      ajax.fetchGet(common.formatStr(config.api.task.business_days, year, month)).then(data => {
        vm.$set(vm.value, 'basic_attendance_day', data.length);
        vm.businessDays = data;
        this.getVacationsMonth(year, month);
      }).catch(errors => {
        helper.show_errors(errors);
      });
    },
    calculateDays(vacation) {
      const startDate = new Date(vacation.start_date);
      const endDate = new Date(vacation.end_date);
      let totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1; // 计算总天数
      return Math.ceil(totalDays); // 向上舍入总天数
    },
    getVacationsMonth(year, month) {
      const vm = this;
      vm.vacationsDisabledDays = [];
      vm.vacationDays = [];
      ajax.fetchGet(common.formatStr(config.api.employee.vacations_month, year, month)).then(data => {
        vm.vacationDays = data;
        vm.calculateContractDate();
      }).catch(errors => {
        helper.show_errors(errors);
      });
    },
    getVacation(fullDay) {
      return this.vacationDays.find(vacation => {
        const startDate = vacation.start_date;
        const endDate = vacation.end_date;
        return startDate <= fullDay && endDate >= fullDay;
      });
    },
    calculateContractDate() {

      function setReadOnlyFields(fieldNames, readOnly, clearValue = false) {
        for (const fieldName of fieldNames) {
          const field = vm.get_field_by_name(fieldName);
          if (field) {
            vm.$set(field, 'read_only', readOnly);
            if (clearValue) {
              vm.value[fieldName] = null;
            }
          }
        }
      }

      const vm = this;
      vm.programmaticChange = true;
      const contractLastDay = vm.contract.end_date;
      const year = vm.value['year'];
      const month = vm.value['month'];
      for (const day of vm.calendarDays) {
        const fullDay = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const vacation = vm.getVacation(fullDay);
        const isBusinessDay = vm.businessDays.includes(fullDay);
        let startTime = `start_time_${day}`;
        let endTime = `end_time_${day}`;
        let type = `type_${day}`;

        let startTime2 = `start_time_${day}_2`;
        let endTime2 = `end_time_${day}_2`;
        let type2 = `type_${day}_2`;

        const fieldNames = [startTime, endTime, type, startTime2, endTime2, type2];
        if (contractLastDay && fullDay > contractLastDay) {
          setReadOnlyFields(fieldNames, true, true);
        } else if (vacation) {
          const readonlyFieldNames = [startTime, endTime, type];
          const startDate = new Date(vacation.start_date);
          const endDate = new Date(vacation.end_date);
          const startDay = startDate.getDate();
          const endDay = endDate.getDate();
          if (vacation.category === '01') {
            // 有給の場合
            let workStartTime = vm.value[`work_start_time`];
            let workEndTime = vm.value[`work_end_time`];
            if (vm.businessDays.includes(fullDay)) {
              vm.vacationsDisabledDays.push(day);
              if (vacation.is_start_half && day === startDay) {
                workStartTime = vm.adjust_work_time(workEndTime, -4);
                let workStartTime2 = vm.value[`work_start_time_2`];
                let workEndTime2 = vm.adjust_work_time(workStartTime2, 5);
                vm.value[type2] = '01';
                vm.value[startTime2] = vm.value[`work_start_time_2`];
                vm.value[endTime2] = workEndTime2;
              } else if (vacation.is_end_half && day === endDay) {
                let workEndTime2 = vm.value[`work_end_time_2`];
                workEndTime2 = vm.adjust_work_time(workEndTime2, -4);
                workEndTime = vm.adjust_work_time(workStartTime, 5);
                vm.value[type2] = '01';
                vm.value[startTime2] = workEndTime2;
                vm.value[endTime2] = vm.value[`work_end_time_2`];
              } else {
                vm.value[type2] = '02';
                vm.value[startTime2] = null;
                vm.value[endTime2] = null;
                readonlyFieldNames.push(startTime2, endTime2, type2);
              }
              vm.value[type] = '03';
              vm.value[startTime] = workStartTime;
              vm.value[endTime] = workEndTime;
              setReadOnlyFields(readonlyFieldNames, true);
            } else {
              setReadOnlyFields(fieldNames, false);
            }
          } else {
            // 有給以外の場合
            vm.vacationsDisabledDays.push(day);
            readonlyFieldNames.push(startTime2, endTime2, type2);
            setReadOnlyFields(readonlyFieldNames, true, true);
          }
        } else {
          if (!isBusinessDay && (vm.weekday(day) !== '土' && vm.weekday(day) !== '日')) {
            if (vm.value[type] == null || vm.value[type] === '10') {
              vm.value[type] = '10';
              setReadOnlyFields([startTime, endTime], true);
            }
            if (vm.value[type2] == null || vm.value[type2] === '10') {
              vm.value[type2] = '10';
              setReadOnlyFields([startTime2, endTime2], true);
            }
          } else {
            setReadOnlyFields(fieldNames, false);
          }
        }
      }

      vm.$nextTick(() => {
        vm.programmaticChange = false;
      });
    },
  },

  mounted() {
    //新規画面の場合
    if (this.action === 'create') {
      this.loadMonthOptions();
    }
    this.isTimeDisabled(1);
    this.isTimeDisabled(2);
  },
  watch: {
    //基準出勤日数の取得
    watchBusinessDate: {
      handler: function (newVal) {
        if (newVal.year && this.action !== 'create') {
          this.get_business_days(newVal.year, this.value['month']);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState('account', ['user']),
    filteredField() {
      console.log(123)
      // Assuming get_field_by_name returns an object
      const originalField = this.methods.get_field_by_name(`type_${this.day}_2`);
      originalField.choices = originalField.choices.filter(itm => itm.value != '03');
      return originalField

      //   // Filter out unnecessary data
      //   const filteredData = /* Your logic to filter data */;

      //   return filteredData;
    },
    calendarDays() {
      const daysInMonth = new Date(this.value['year'], this.value['month'], 0).getDate();
      return Array.from({length: daysInMonth}, (_, i) => i + 1);
    },
    weekday() {
      const weekdays = ["日", "月", "火", "水", "木", "金", "土"];
      return (day) =>
        weekdays[new Date(this.value['year'], this.value['month'] - 1, day).getDay()];
    },
    watchBusinessDate() {
      return {
        basicAttendanceDay: this.value['basic_attendance_day'],
        year: this.value['year'],
      };
    },
    isDefaultInputTab: {
      get() {
        if (this.currentTabIndex === 0) {
          return this.isDefaultInputTab2
        } else if (this.currentTabIndex === 1) {
          return this.isDefaultInputTab1
        } else {
          return false
        }
      },
      set(value) {
        if (this.currentTabIndex === 0) {
          this.isDefaultInputTab2 = value
        } else if (this.currentTabIndex === 1) {
          this.isDefaultInputTab1 = value
        } else {
          return false
        }
      }
    },
    vacationFields() {
      return [
        {key: 'category_name', label: '休暇種類'},
        {key: 'start_date', label: '開始日'},
        {key: 'end_date', label: '終了日'},
        {key: 'days', label: '日数'},
        {key: 'is_start_half', label: '午後から'}
      ];
    },
    contract() {
      if (this.user && this.user.latest_contract) {
        return this.user.latest_contract;
      }
      return {};
    },
  },
}
</script>.
<style>
.card .setting-container .table td {
  padding: 10px 0 10px 15px;
  vertical-align: middle;
}

.card .duty-container .table th,
.card .duty-container .table td {
  padding: 0.5rem;
  vertical-align: middle;
  border: 1px solid #dee2e6;
  text-align: center;
}

.card .totalling-container .table th {
  padding-left: 0;
  padding-right: 1rem;
  vertical-align: middle;
}

.card .totalling-container .table td {
  vertical-align: middle;
}

.attendance-form .duty-container th {
  font-size: 0.8125rem;
}

.attendance-form th {
  background-color: #f2f2f2;
}

.attendance-form td .form-group {
  margin-bottom: 0;
}

.attendance-form .pt10 {
  padding-top: 10px;
}

.attendance-form .mt20 {
  margin-top: 20px;
}

.card .file-upload fieldset {
  margin-bottom: 0;
}

.checkbox-default {
  flex-grow: 1;
  text-align: right;
  padding-right: 1.5rem;
}

.pt10 {
  padding-top: 10px;
}
</style>